import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { useRef, useState } from 'react';

import { logout } from 'services/authentication/logout';

import { CurrentUserEnum } from 'types/AuthRequired';
import { Features } from 'types/Features';

import { featureEnabled } from 'utils/features';
import { logoutChannelAction } from 'utils/channels/LoginSessionChannel';

import { selectAuthRequiredDomain } from 'store/auth/selectors';
import { AppDispatch } from 'store';
import { pingAtostekApiThunk, showLoginModal } from 'store/auth/slice';
import useModal from 'hooks/useModal';
import SessionTimeoutModal from 'App/modals/SessionTimeoutModal';

const PING = 5000;
const IDLE_INTERVAL = 500;
const HARD_TIMEOUT = 3000000; // 50min
// const SHORT_TIMEOUT = 6000; // use for debugging

const useDVVCardPingTimer = () => {
  const { userInfo } = useSelector(selectAuthRequiredDomain());
  const dispatch = useDispatch<AppDispatch>();
  const timeIdleRef = useRef(0);
  const [showSessionTimeoutModal] = useModal(SessionTimeoutModal);
  const [block, setBlock] = useState(false); // so the timer won't run if the modal is on

  // 10 min
  let autolockTimeout = 600000;

  if (userInfo?.currentDomacareUser?.autolockTimeout) {
    // the autolockTimeout is in seconds, so we convert it to milliseconds
    autolockTimeout = userInfo.currentDomacareUser.autolockTimeout * 1000;
  }

  // checks if the user has moved the mouse or pressed a key
  useInterval(
    async () => {
      if (timeIdleRef.current > autolockTimeout) {
        await logout();
        logoutChannelAction();
        dispatch(showLoginModal());
      } else {
        timeIdleRef.current = timeIdleRef.current + IDLE_INTERVAL;
      }
    },
    userInfo.loginState?.userType === CurrentUserEnum.DVV_CARD_USER_PRINCIPAL &&
      featureEnabled(Features.DVVSmartCardLogin)
      ? IDLE_INTERVAL
      : null,
  );

  // TODO: Adapt for DVV card and autolock when it's activated
  // AreyouThere modal
  useInterval(
    async () => {
      if (timeIdleRef.current > HARD_TIMEOUT) {
        // display a modal asking if the user is still there
        showSessionTimeoutModal({
          cb: () => setBlock(false),
        });
        setBlock(true);
      } else {
        timeIdleRef.current = timeIdleRef.current + IDLE_INTERVAL;
      }
    },
    featureEnabled(Features.LogoutPrompt) && userInfo?.loginState?.loggedIn && !block ? IDLE_INTERVAL : null,
  );

  useEffectOnce(() => {
    const handleKeyDown = () => (timeIdleRef.current = 0);
    const handleKeyUp = () => (timeIdleRef.current = 0);
    const setIdle = () => (timeIdleRef.current = 0);

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    // even listener for mouse movement
    document.addEventListener('mousemove', setIdle);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mousemove', setIdle);
    };
  });

  // this pings whether the card is inside the reader slot
  useInterval(
    () => {
      dispatch(pingAtostekApiThunk());
    },
    userInfo.loginState?.userType === CurrentUserEnum.DVV_CARD_USER_PRINCIPAL &&
      featureEnabled(Features.DVVSmartCardLogin)
      ? PING
      : null,
  );
};

export default useDVVCardPingTimer;
