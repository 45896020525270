import { FC } from 'react';
import { useIntl } from 'react-intl';

import Modal, { ModalProps, ModalSize } from 'common/Modal';

import loginMessages from 'Login/messages';

import Login from './Login';

const LoginModal: FC<Pick<ModalProps, 'show'>> = ({ show }) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      blurBackground
      show={show}
      title={formatMessage(loginMessages.youHaveBeenLoggedOut)}
      onHide={() => {}}
      size={ModalSize.SM}
      error={
        <>
          {formatMessage(loginMessages.sessionInvalidated)}. {formatMessage(loginMessages.loginAgain)}
        </>
      }
    >
      <Modal.Body>
        <Login />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default LoginModal;
