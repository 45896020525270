import { defineMessages } from 'react-intl';

export default defineMessages({
  sessionTimeout: {
    id: 'app.app.sessionTimeout',
    defaultMessage: 'Session timeout',
  },
  continueSession: {
    id: 'app.app.continueSession',
    defaultMessage: 'Continue session',
  },
  sessionExpire: {
    id: 'app.app.sessionExpire',
    defaultMessage: 'Your session will expire in',
  },
  continueSessionInfo: {
    id: 'app.app.continueSessionInfo',
    defaultMessage: 'Please click “Continue session” to keep working or “Logout” to end your session now.',
  },
  authenticationError: {
    id: 'app.app.authenticationError',
    defaultMessage: 'Authentication error',
  },
  cookieMessage: {
    id: 'app.app.cookieMessage',
    defaultMessage:
      'Cookies are essential for the operation of the site. By continuing to use the site, you accept the use of cookies.',
  },
  readMoreAboutCookies: {
    id: 'app.app.readMoreAboutCookies',
    defaultMessage: 'Read more about how we use cookies',
  },
  readAlso: {
    id: 'app.app.readAlso',
    defaultMessage: 'Read also about our',
  },
  thirdPartyLicenses: {
    id: 'app.app.thirdPartyLicenses',
    defaultMessage: 'Third party licenses',
  },
});
