const CLIENT_SESSION_TOKEN = 'clientSessionToken';
export const COOKIES_CONSENT = 'cookiesConsent';

export const setClientSessionToken = (token: string) => {
  localStorage.setItem(CLIENT_SESSION_TOKEN, token);
};

export const getClientSessionToken = () => localStorage.getItem(CLIENT_SESSION_TOKEN);

export const removeClientSessionToken = () => localStorage.removeItem(CLIENT_SESSION_TOKEN);

export const isClientSessionTokenStale = (token: string | null) => token !== null && token !== getClientSessionToken();

// can only be set to true (not false) because accepting is mandatory for the app to work
export const setCookiesConsent = () => localStorage.setItem(COOKIES_CONSENT, 'true');
export const getCookiesConsent = () => localStorage.getItem(COOKIES_CONSENT) !== null;
