import { DATABASE_DATE_FORMAT, DATABASE_TIME_FORMAT } from 'utils/dateUtil';

import { InstrumentsDetail, Languages } from './types';
import messages from './messages';

export const MIN_SCALE_FACTOR = 0.1;
export const MAX_SCALE_FACTOR = 2.4;
export const SCALE_STEP = 0.2;

export const URL = 'https://update.domacare.fi/RAIv2';
export const PDF_WORKER_CDN = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.js';
export const RAI_TIME_FORMAT = `${DATABASE_DATE_FORMAT}, ${DATABASE_TIME_FORMAT}`;
export const languages: { [key: string]: string } = {
  fi: Languages.FIN,
  sv: Languages.SVE,
  et: Languages.EST,
  en: Languages.FIN,
};

export const instruments: { [key: string]: string } = {
  LTC: InstrumentsDetail.LTC,
  HC: InstrumentsDetail.HC,
  CMH: InstrumentsDetail.CMH,
  ID: InstrumentsDetail.ID,
  CHA: InstrumentsDetail.CHA,
  LTCF_EST: InstrumentsDetail.LTCF_EST,
  CHYMH_DD: InstrumentsDetail.CHYMH_DD,
  LTCF: InstrumentsDetail.LTCF,
};

export const errorValidationMessages: { [key: string]: { id: string; defaultMessage: string } } = {
  EMPTY_VALUE_NOT_ALLOWED: messages.emptyValueError,
  ILLEGAL_VALUE_DEPENDANT_ON_OTHER_FIELD: messages.illegalValueError,
  WRONG_INPUT: messages.wrongInputError,
  INPUT_NOT_ONE_OF_THE_CHOICES: messages.noChoicesError,
  DAY_FIELD_CANNOT_CONTAIN_MINUTES_FIELD_AMOUNT: messages.noMinuteFieldError,
  DAY_FIELD_CANNOT_CONTAIN_HOUR_FIELD_AMOUNT: messages.noHourFieldError,
  TABLE_MUST_BE_FILLED: messages.tableError,
  MEDICATION_TABLE_CONTAINS_EMPTY_MANDATORY_VALUES: messages.mandatoryFieldError,
  PREVIOUS_ASSESSMENT_OWN_GOAL_MISSING_EVALUATION: messages.previousAssessmentOwnGoalError,
  PREVIOUS_ASSESSMENT_OWN_GOAL_EVALUATION_WRONG_VALUE: messages.previousAssessmentEvaluationError,
  DIAGNOSES_SOMATIC_CONTAINS_NO_ANSWER: messages.diagnosesError,
  DIAGNOSES_REGULAR_NO_STATUS: messages.diagnosesNoStatus,
  SKIPPED_UNSKIPPABLE: messages.unskippableError,
  WRONG_DATE_FORMAT: messages.wrongDateFormatError,
  NOT_A_NUMBER: messages.notANumberError,
  NOT_AN_INTEGER: messages.notANumberError,
  INVALID_NUMBER: messages.invalidNumberError,
  PARTICIPANT_MISSING_VALUE: messages.participantMissingValueError,
  PARTICIPANT_WRONG_PROFESSION_TITLE: messages.participantWrongProfessionTitleError,
  PARTICIPANT_WRONG_ORGANIZATION_RELATION: messages.participantWrongOrganizationError,
  MUNICIPALITY_NAME_AND_CODE_MISMATCH: messages.municipalityMismatchError,
};

export const GET_INSTRUMENTS_LIST_SUCCESS = 'app/RAIv2v2/GET_INSTRUMENTS_LIST_SUCCESS';
export const START_NEW_ASSESSMENT_SUCCESS = 'app/RAIv2v2/START_NEW_ASSESSMENT_SUCCESS';

export const GET_CUSTOMER_ALL_ASSESSMENTS_SUCCESS = 'app/RAIv2/GET_CUSTOMER_ALL_ASSESSMENTS_SUCCESS';

export const GET_CUSTOMER_DETAILS_SUCCESS = 'app/RAIv2/GET_CUSTOMER_DETAILS_SUCCESS';

export const RESET_ASSESSMENTS = 'app/RAIv2/RESET_ASSESSMENTS';

export const GET_SINGLE_ASSESSMENT_SUCCESS = 'app/RAIv2/GET_SINGLE_ASSESSMENT_SUCCESS';

export const RESET_RAI = 'app/RAIv2/RESET_RAI';

export const GET_INSTRUMENT_QUESTIONS_SUCCESS = 'app/RAIv2/GET_INSTRUMENT_QUESTIONS_SUCCESS';

export const GET_INSTRUMENT_QUESTIONS_FAILED = 'app/RAIv2/GET_INSTRUMENT_QUESTIONS_FAILED';
export const POST_ANSWER = 'app/RAIv2/POST_ANSWER';
export const POST_ANSWER_SUCCESS = 'app/RAIv2/POST_ANSWER_SUCCESS';
export const POST_ANSWER_FAILED = 'app/RAIv2v2/POST_ANSWER_FAILED';
export const GET_ASSESSMENT_ANSWERS_SUCCESS = 'app/RAIv2v2/GET_ASSESSMENT_ANSWERS_SUCCESS';
export const GET_ASSESSMENT_ANSWERS_FAILED = 'app/RAIv2v2/GET_ASSESSMENT_ANSWERS_FAILED';

export const SET_RAI_IS_LOADING = 'app/RAIv2/SET_RAI_IS_LOADING';

export const GET_MODIFIED_CATEGORIES = 'app/RAIv2/GET_MODIFIED_CATEGORIES';
export const GET_MODIFIED_CATEGORIES_SUCCESS = 'app/RAIv2/GET_MODIFIED_CATEGORIES_SUCCESS';

export const RESET_INQUIRY_MODE = 'app/RAIv2/RESET_INQUIRY_MODE';

export const GET_ASSESSMENT_MEASUREMENTS = 'app/RAIv2/GET_ASSESSMENT_MEASUREMENTS';

export const GET_ASSESSMENT_MEASUREMENTS_SUCCESS = 'app/RAIv2/GET_ASSESSMENT_MEASUREMENTS_SUCCESS';

export const SET_FOCUSED = 'app/RAIv2/SET_FOCUSED';

export const GET_RAI_NOTES_SUCCESS = 'app/RAIv2/GET_RAI_NOTES_SUCCESS';
