import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Modal, { ModalSize } from 'common/Modal';
import Button from 'common/Button';
import ActiveNotificationBellIcon from 'common/icons/ActiveNotificationBellIcon';

import { getUserAPI } from 'services/user';

import commonMessages from 'translations/CommonTranslations';

import { SESSION_PROMPT_TIMEOUT } from 'App/constants';
import sidenavMessages from 'sharedComponents/Sidenav/messages';

import messages from '../messages';
import useAppDispatch from 'hooks/useAppDispatch';
import { removeClientSessionTokenThunk, showLoginModal } from 'store/auth/slice';
import { logout } from 'services/authentication/logout';

type Props = {
  show: boolean;
  onHide: () => void;
  cb: () => void;
};

const SessionTimeoutModal: React.FC<Props> = ({ show, onHide, cb }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const timeOnStart = useMemo(() => new Date().getTime() + SESSION_PROMPT_TIMEOUT, []);
  const [remaining, setRemaining] = useState(SESSION_PROMPT_TIMEOUT);

  const handleLogout = async () => {
    onHide();
    await logout();
    dispatch(removeClientSessionTokenThunk());
    dispatch(showLoginModal());
  };

  // in case the session is expected to be continued, a call should be made to refresh the cookies
  const handleContinueSession = async () => {
    await getUserAPI();
    cb();
    onHide();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (remaining > 0) {
        setRemaining(timeOnStart - new Date().getTime());
      } else {
        handleLogout();
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  const minutes = remaining <= 0 ? 0 : Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = remaining <= 0 ? 0 : Math.ceil((remaining % (1000 * 60)) / 1000);

  return (
    <Modal
      show={show}
      onHide={handleContinueSession}
      title={formatMessage(messages.sessionTimeout)}
      size={ModalSize.XS}
    >
      <Modal.Body>
        <div className="d-flex">
          <div className="p-1">
            <ActiveNotificationBellIcon />
          </div>
          <div className="p-1">
            <div>
              <p>{formatMessage(messages.sessionExpire)}</p>
            </div>
            <div className="h1 font-weight-bold">
              {minutes} {formatMessage(commonMessages.min)} {seconds} {formatMessage(commonMessages.sec)}
            </div>
            <div>{formatMessage(messages.continueSessionInfo)}</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={handleLogout}>{formatMessage(sidenavMessages.logout)}</Button>
          <Button onClick={handleContinueSession} success>
            {formatMessage(messages.continueSession)}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default SessionTimeoutModal;
